
import { HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { NgModule, ModuleWithProviders, InjectionToken} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { GoogleMapsModule } from '@angular/google-maps'

import { VeComponent } from './ve/ve.component';
import { ProductComponent } from './helpers/product.component';

import { NavigationService } from './navigation/navigation.service';
import { AuthService } from './auth/auth.service';
import { UtilsService } from './services/utils.service';
import { AuthGuard } from './auth/auth-guard.service';
import { CanDeactivateGuard} from './guards/can-deactivate-guard.service'

// helpers
import { ZoomDirective } from './helpers/zoom.directive';
import { DeferLoadDirective } from './helpers/defer-load.directive';
import { PriceComponent } from './helpers/price.component';
import { ImageComponent } from './helpers/image.component';
import { ShowB2bDirective } from './helpers/show-b2b.directive';
import { FbLikeComponent } from './helpers/fb-like.component';
import { FbChatComponent } from './helpers/fb-chat.component';

// pipes
import { ImgPipe } from './pipes/img.pipe';
import { StatusPipe } from './pipes/status.pipe';

// import { FlexLayoutModule } from '@angular/flex-layout';
// import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Observable, of } from 'rxjs';
import { shareReplay, map, catchError } from 'rxjs/operators';
import { SideNavComponent } from './sidenav/side-nav.component';
import { ModalDialogComponent } from './services/modal.component';
import { ModalDialogInstanceService } from './services/modal-instance.service';
import { FilterPipe } from './pipes/filter.pipe';
import { AvailabilityPipe } from './pipes/availability.pipe';
// import { CurrencyProxyPipe } from './pipes/currency.pipe';

export const MAP_LOADED = new InjectionToken<Observable<boolean>>('Google Maps API Loaded');
function loadMapApi(httpClient: HttpClient) {
    return httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyCyxyLkbbI-1WxumOyvPi8_Cbst4uFogsQ', 'callback').pipe(
        shareReplay({bufferSize: 1, refCount: true}),
        map(() => true),
        catchError(e => {
            return of(false);
        })
    );
}

@NgModule({
  imports: [CommonModule, HttpClientJsonpModule, RouterModule],

  declarations: [SideNavComponent, ProductComponent, ModalDialogComponent, ImgPipe, StatusPipe, FilterPipe, AvailabilityPipe,
    DeferLoadDirective, ZoomDirective, PriceComponent, VeComponent,
    ImageComponent, FbLikeComponent, FbChatComponent, ShowB2bDirective],

  exports: [SideNavComponent, ProductComponent,
    CommonModule, RouterModule, DeferLoadDirective, PriceComponent, StatusPipe, ImgPipe, FilterPipe, AvailabilityPipe, ZoomDirective, VeComponent,
    ImageComponent, FbLikeComponent, FbChatComponent, ShowB2bDirective
  ],

})


export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [NavigationService, UtilsService, AuthService, AuthGuard, CanDeactivateGuard, ModalDialogInstanceService,
        { provide: MAP_LOADED, useFactory: loadMapApi, deps: [HttpClient]}
      ]
    };
  }
}

