import { Component, ElementRef, AfterViewInit, Input, PLATFORM_ID, Inject, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationService } from '../navigation/navigation.service';
@Component({
    selector: 'fb-chat',
    template: `
    <div id="fb-messenger-fake-button" style="position: fixed; z-index: 100; bottom: 12px; right: 8px;" (click)="initAsync()" [class.active]="loading" *ngIf="!loaded">
	<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 1024 1024" aria-hidden="true">
		<circle fill="#0084ff" style="fill: var(--msgr-color, #0084ff)" cx="512" cy="512" r="512"/>
		<g id="spinner" fill="#fff"><circle cx="512" cy="239" r="47"/><circle cx="375" cy="275" r="47" fill-opacity="0.9"/><circle cx="275" cy="375" r="47" fill-opacity="0.8"/><circle cx="239" cy="512" r="47" fill-opacity="0.7"/><circle cx="275" cy="649" r="47" fill-opacity="0.6"/><circle cx="375" cy="749" r="47" fill-opacity="0.5"/><circle cx="512" cy="785" r="47" fill-opacity="0.4"/><circle cx="649" cy="749" r="47" fill-opacity="0.3"/><circle cx="749" cy="649" r="47" fill-opacity="0.2"/><circle cx="785" cy="512" r="47" fill-opacity="0.1"/></g>
		<path id="logo" fill="#fff" d="M512 171c192 0 341 141 341 331S704 833 512 833c-35 0-68-5-99-13-6-2-12-1-18 1l-68 30c-17 8-37-5-38-24l-2-61c0-7-4-14-9-19a324 324 0 01-107-245c0-190 149-331 341-331zM307 599c-10 15 9 32 24 21l107-82c8-5 18-5 25 0l80 60c24 18 58 12 74-14l100-159c10-15-9-32-24-21l-107 82c-8 5-18 5-25 0l-80-60a51 51 0 00-74 13L307 599z"/>
	</svg>
	</div>
    <div *ngIf="loading || loaded" class="fb-customerchat" attribution=setup_tool page_id="dsadad" theme_color="#0084ff" greeting_dialog_display="hide" logged_in_greeting="Witaj, w czym możemy pomóc ?" logged_out_greeting="Witaj, w czym możemy pomóc ?"></div>
    `
})

export class FbChatComponent implements AfterViewInit {
    @Input() url = '';
    public loading = false;
    public loaded = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private cdr: ChangeDetectorRef, private navigationService: NavigationService) {}

    initAsync(withDialog = false) {
        if (isPlatformBrowser(this.platformId)) {
            this.loading = true;
            if (!window['fbAsyncInit']) {
                window['fbAsyncInit'] = () => {
                    this.loaded = true;
                    window['FB'].init({
                        appId: 'app-id',
                        autoLogAppEvents: true,
                        xfbml: true,
                        version: 'v10.0'
                    });

                    window['FB'].Event.subscribe('customerchat.load', () => {
                        console.log('CHAT LOADED');
                        if (withDialog) {
                            window['FB'].CustomerChat.showDialog();
                        }
                    });

                    this.loaded = true;
                    this.cdr.detectChanges();
                };
            } else {
                window['FB'].init({
                    appId: 'app-id',
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v10.0'
                });

                window['FB'].Event.subscribe('customerchat.load', () => {
                    console.log('CHAT LOADED');
                    if (withDialog) {
                        window['FB'].CustomerChat.showDialog();
                    }
                });
                this.loaded = true;
                this.cdr.detectChanges();
            }

            // load facebook sdk if required
            const url = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
            if (!document.querySelector(`script[src='${url}']`)) {
                let script = document.createElement('script');
                script.src = url;
                document.body.appendChild(script);
                this.cdr.detectChanges();
            }
        }
    }

    ngAfterViewInit(): void {
        this.navigationService.triggerChat.subscribe(r => {
            console.log('TRIGGER CHAT BUBBLE');
            if (this.loaded) {
                window['FB'].CustomerChat.showDialog();
            } else {
                this.initAsync(true);
            }
        })
    }
}