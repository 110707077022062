import { Pipe, PipeTransform } from '@angular/core';
import { url } from 'inspector';
import { environment } from '../../../environments/environment';

@Pipe({
    name: 'img'
})
export class ImgPipe implements PipeTransform {

    private cdn = environment.cdn;

    transform(image: any, type: string, format:string = '', prefix:string = ''): any {
        if (!image) {
            return '';
        }

        let img;
        let i = 0;
        if (image['photos']) {
            if (type === 'mobile') i = 1;
            img = image['photos'][i];
        } else {
            if(Array.isArray(image)) {
                if (type === 'mobile') i = 1;
                img = image[i];
            } else {
                img = image;
            }
        }
        if (img) {
            let filename = img;
            let url = '';
            if (typeof img === 'object') { //from grid
                filename = img['filename'];
                if (format === 'extension') {
                    return img['extension']; //for mp4 in slider
                }
                if (format === 'webp') {
                    filename = filename.slice(0, -3)+'webp';
                }  
                url = this.cdn + '/' + img['module'] + '/' + img['dir'] + '/' + prefix + filename;
            } else {//string array
                if (format === 'webp') {
                    filename = filename.slice(0, -3)+'webp';
                }  
                url = filename;
            }        

            return url;
        } else {
            return '';
        }
    }
}