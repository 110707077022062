import {Directive, Input, OnInit, OnDestroy, TemplateRef, ViewContainerRef, Inject, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationService } from '../navigation/navigation.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Settings } from '../navigation/pages.model';
@Directive({ selector: '[appShowB2b]' })

export class ShowB2bDirective implements OnInit {

    private role = 'client';
    private scopes:string[] = [];
    public settings: Settings;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private navigationService: NavigationService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef
    ) {}
    @Input() public appShowB2b: string;

    ngOnInit() {
        // const settings = <Settings>this.navigationService.getNavigation().value.settings;

        if (isPlatformBrowser(this.platformId)) {
            this.role = localStorage.getItem('user_role') || 'client';
            this.authService.isLoggedIn.subscribe(state => {
                const role = localStorage.getItem('user_role') || 'client';
                this.scopes = state.scopes || [];
                // console.log('HELPER LOGGED ', role, this.role, this.settings);
                if (this.settings) {
                    this.role = role;
                    this.updateShop();
                }
            });
        }


        this.navigationService.navigation.subscribe((setup: any) => {
            this.settings = setup.settings;
            if (this.scopes.length == 0){
                if (+this.settings.b2c == 1 && +this.settings.b2b_retail == 0 && this.role == 'client') {
                    this.scopes.push('allow_orders');
                } else {
                    this.scopes.push('disallow_orders');
                }
                if (+this.settings.b2b == 1 && this.role == 'client_b2b') {
                    this.scopes.push('allow_orders');
                } else {
                    this.scopes.push('disallow_orders');
                }
                if (+this.settings.b2b_retail == 1 && this.role == 'client') this.scopes.push('disallow_orders');
                if (+this.settings.b2b_retail_price == 0 && this.role == 'client') this.scopes.push('show_prices');
            }
            this.updateShop();
        });
    }

    updateShop() {
        // console.log('ROLE', 
        // this.role, 
        // this.scopes,
        // this.settings?.b2b_retail,
        // this.settings?.b2b_retail_price);

        if (this.scopes.includes(this.appShowB2b)) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
        this.cdr.detectChanges();
    }
}
