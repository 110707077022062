import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: 'image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [':host{width:100%; display:flex; line-height:0;}']
})

export class ImageComponent {

  public photo = false;
  public loaded = 'loaded';
  @Input() public url: string;
  @Input() public natural: boolean = true;
  @Input() public proportion: number = 100;

  constructor(private cdr: ChangeDetectorRef) {}

  initPicture() {
    this.photo = true;
    this.cdr.detectChanges();
  }

  loadedPicture() {
    this.loaded = 'loaded';
    this.cdr.detectChanges();
  }

}
