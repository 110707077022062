import { Injectable, Inject, LOCALE_ID, PLATFORM_ID, EventEmitter } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { pluck, shareReplay, publishReplay, refCount } from 'rxjs/operators';
import { Page, Setup, Popup, Downloads, Review, Slides } from './pages.model';
import { environment } from './../../../environments/environment';

/**
 * This class provides the NameList service with methods to read names and add names.
 */
@Injectable()
export class NavigationService {

  public navigation = new ReplaySubject();
  public currentPage = new BehaviorSubject({});
  public pageSettings = new BehaviorSubject({});
  public code = new BehaviorSubject(false);
  public mobile = new BehaviorSubject(true);
  public triggerChat = new EventEmitter<boolean>();
  // public b2b = new BehaviorSubject({b2b: false, b2b_retail: false, b2b_retail_price: false});

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(LOCALE_ID) public localeId: string, @Inject(DOCUMENT) private doc, private httpClient: HttpClient, private titleService: Title, private metaService: Meta) {}

  public items(): Observable<Page[]> {
    return this.httpClient.get<Page[]>(environment.api + '/menu');
  }

  public item(slug: string): Observable<Page[]> {
    return this.httpClient.get<Page[]>(environment.api + '/page/' + slug, {headers: {'Content-Language': this.localeId}} );
  }

  public settings(lang: string): Observable<Setup> {
    return this.httpClient.get<Setup>(environment.api + '/settings/' + lang).pipe(publishReplay(1), refCount());
  }

  public popup(query: any): Observable<Popup> {
    const params = new HttpParams({fromObject: query});
    return this.httpClient.get<Popup>(environment.api + '/popup', { headers: {'Content-Language': this.localeId}, params: params });
  }

  public downloads(): Observable<Downloads> {
    return this.httpClient.get<Downloads>(environment.api + '/downloads');
  }

  public email(data: any): Observable<any> {
    return this.httpClient.post(environment.api + '/email', data);
  }

  public gallery(dir: string): Observable<any> {
    return this.httpClient.get(environment.api + '/gallery/' + dir);
  }

  public google_reviews(): Observable<Review[]> {
    return this.httpClient.get<Review[]>(environment.api + '/google/reviews');
  }

  public google_places(): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.api + '/google/places');
  }

  public order_confirm(type: string, token: string): Observable<any> {
    return this.httpClient.get<any>(environment.api + '/order/' + type +'/'+ token);
  }

  public reset_password(type: string, token: string): Observable<any> {
    return this.httpClient.get<any>(environment.api + '/auth/' + type +'/'+ token);
  }

  public slides(query: any): Observable<Slides> {
    const params = new HttpParams({fromObject: query});
    params['front'] = true;
    return this.httpClient.get<Slides>(environment.api + '/slides', { params: params});
  }


  public setMeta(title: string, keywords: string, description: string, canonical: string = '') {
    this.titleService.setTitle(title);
    this.metaService.updateTag({content: description}, 'name="description"');
    this.metaService.updateTag({content: keywords}, 'name="keywords"');
    if (canonical !== '') {
      const head = this.doc.getElementsByTagName('head')[0];
      var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
      if (element==null) {
        element= this.doc.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel','canonical')
      element.setAttribute('href',canonical);
    }
  }

  public setLink(path: string) {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'preload');
    link.setAttribute('as', 'image');
    link.setAttribute('href', path);
    this.doc.head.appendChild(link);
  }

  public setSocial(url: string, title: string, description: string, image: string) {
    this.metaService.updateTag({content: url}, 'property="og:url"');
    this.metaService.updateTag({content: title}, 'property="og:title"');
    this.metaService.updateTag({content: description}, 'property="og:description"');
    this.metaService.updateTag({content: image}, 'property="og:image"');
  }
  public showFacebookChatDialog() {
    console.log('EMIT CHAT');
    this.triggerChat.emit(true);
  }

  public getNavigation() {
    return this.navigation;
  }

  public getCurrentPage() {
    return this.currentPage;
  }

  public getCode() {
    return this.code;
  }

  public getMobile() {
    return this.mobile;
  }
}

