import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, ViewChild, ElementRef, HostBinding, NgZone, Output, EventEmitter } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { Product, ProductVariant } from './../../offer/offer.model';
import { Discount } from '../cart/cart.model';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { utils } from './utils';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-product',
  templateUrl: 'product.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})

export class ProductComponent implements OnInit, OnDestroy {

  public cdn = environment.cdn;
  public photo = false;
  public link = [];
  public params = {};
  public loaded = 'none';
  public discounts: Discount[];
  public sub: Subscription = null;
  public quantity = 1;
  public path_offer_detail = '';
  @Input() public type: string;
  @Input() public product: Product;
  @Output() public changed = new EventEmitter<any>();
  @ViewChild('quantity') quantityInput: ElementRef;
  @HostBinding('class.b2b') b2b: boolean = false;
  @HostBinding('class.small') small: boolean = false;
  @HostBinding('class.loading-content') loading: boolean = false;

  constructor(private router: Router, private cdr: ChangeDetectorRef, private cartService: CartService, private datePipe: DatePipe, private zone: NgZone) {
    this.path_offer_detail = this.router.config.find(x => x.data?.module === 'OfferDetailsModule')?.data.path || '/';
  }

  ngOnInit() {
    if (this.type == 'b2b') this.b2b = true;
    if (this.type == 'small') this.small = true;
    if (this.product.id < 0) {
      this.loading = true;
      const tempProductVariant = new ProductVariant();

      this.product.title = '&nbsp;';
      this.product.series = '&nbsp;';
      this.product.variants = [tempProductVariant];
      this.product.variant_selected = 0;
    }

    this.link = ['/'+this.path_offer_detail, this.product.path,  this.product.variant_id];
    this.sub = this.cartService.discounts$.subscribe(discounts => {
      this.discounts = discounts || [];
      this.cdr.detectChanges();
    }, error => {
      // console.log(error);
    });
  }

  addToCart() {
    // let quantity = this.quantityInput.nativeElement.value;
    if (this.quantity < 1) {
      this.quantity = 1;
    }

    // && this.isLoggedIn && this.role == 'client_b2b'
    let supply = $localize `Przewidywana dostawa: nieznana`;
    if (this.product.variants[this.product.variant_selected].date_supply && this.product.variants[this.product.variant_selected].quantity <= 10){
      supply = $localize `Przewidywana dostawa: ` + this.datePipe.transform(this.product.variants[this.product.variant_selected].date_supply, 'shortDate');
    }

    let inzone = NgZone.isInAngularZone();
    if (!inzone) { //fix for slider
      this.zone.run(() => {
        this.cartService.add(+this.product.id, +this.product.variant_id, this.quantity, [], 1, false, '', this.product.extra_1, this.product.extra_2, this.product.extra_3, this.product.extra_4);
      })
    } else {
      this.cartService.add(+this.product.id, +this.product.variant_id, this.quantity, [], 1, false, '', this.product.extra_1, this.product.extra_2, this.product.extra_3, this.product.extra_4);
    }

  }

  addToFavorites(id_product_variant: number) {
    this.cartService.addToFavorites(id_product_variant);
  }

  removeFromFavorites(id_product_variant: number) {
    this.cartService.removeFromFavorites([id_product_variant]).then((id:any)=>{
      this.changed.next({id_product_variant: id, delete: true});
    });
  }

  selectProduct(id_product_variant: number) {
    this.changed.next({id_product_variant: id_product_variant, delete: false});
  }

  plus() {
    // if (this.quantity < this.product.variants[0].quantity) {
      this.quantity++;
      this.cdr.detectChanges();
    // }
  }

  minus() {
    if (this.quantity > 1) {
      this.quantity--;
      this.cdr.detectChanges();
    }
  }

  pushData() {


    if((<any>window).dataLayer) {
      const discounted_price = utils.calculateDiscountPrice(this.discounts, this.product.categories, this.product.id_manufacturer, this.product.tax, this.product.variants[this.product.variant_selected].price, this.product.variants[this.product.variant_selected].price_promo, this.product.id, true);
      const title = this.product.title.split('<br>');
      let universal_item = {
        name: title[0],
        id: this.product.id.toString(),
        price: discounted_price,
        brand: this.product.manufacturer.name,
        category: (this.product.categories.length > 0  ? this.product.categories[this.product.categories.length - 1].name : ''),
        variant: this.product.title1 + (this.product.title2 ? ', '+this.product.title2 : ''),
        position: 1
      };
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
          'click': {
            'products': [universal_item]
          }
        }
      });
    }
  }

  navigate() {
    console.log(this.path_offer_detail);
    let inzone = NgZone.isInAngularZone();
    if (!inzone) { //fix for slider
        this.zone.run(() => {
          this.pushData();
          this.router.navigate(['/'+this.path_offer_detail, this.product.path]);
        })
    } else {
      this.pushData();
      this.router.navigate(['/'+this.path_offer_detail, this.product.path]);
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
