
<div class="gutter hidex flx-col flx-gap-quadruple bggrey">
    <div class="flx-col flx-cntr-cntr"> 
    <h1>Whoops!</h1>
    <p><b i18n>Przepraszamy ale strona której szukasz nie istnieje.</b></p>
    <p i18n>Znaleźliśmy dla Ciebie dodatkowo kilka wyróżnionych produktów które mogą Cię zainteresować.</p>
    </div>

</div>
<div class="gutter gutter-b hidex flx-col flx-gap-quadruple">
    <div class="flx-col flx-gap-default">
        <div class="fancy-box flx-dynamic flx-gap-default flx-strt-strt flx-btwn-end-md" (appDeferLoad)="fetchFeatured()">
            <div class="flx-col">
                <h4>{{ sitename }}</h4>
                <h2 i18n>WYRÓŻNIONE PRODUKTY</h2>
            </div>
            <a class="link" [routerLink]="['/'+path_offer]" i18n>Zobacz wszystkie</a>
        </div>

        <swiper [slidesPerView]="4" [spaceBetween]="16" [navigation]="true" [breakpoints]="{'@0.00': {slidesPerView: 2}, '@0.75': {slidesPerView: 2}, '@1.00': {slidesPerView: 3}, '@1.50': {slidesPerView: 4}}">
            <ng-template swiperSlide *ngFor="let product of products; let j = index;">
                <app-product [product]="product"></app-product>
            </ng-template>
        </swiper>
    </div>
</div>

<div class="gutter gutter-b hidex flx-col flx-gap-quadruple">
    <div class="flx-col flx-cntr-cntr"> 
    <h3 i18n>Nie widzisz nic interesującego ?</h3>
    <p i18n>Skorzystaj z naszej wyszukiwarki na górze strony, zobacz pełną ofertę lub skontaktuj się nami.</p>
    <div class="buttons gutter-v-half flx-dynamic flx-gap-default flx-cntr-cntr"> 
        <a class="stroked-button" [routerLink]="['/']" i18n>strona główna</a>
        <a *ngIf="path_offer != ''" class="stroked-button" [routerLink]="['/'+path_offer]" i18n>nasza oferta</a>
        <a *ngIf="path_contact != ''" class="stroked-button" [routerLink]="['/'+path_contact]" i18n>kontakt</a>
        </div>
    </div>
</div>
