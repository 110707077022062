<div id="master-loader-bar" class="loader" *ngIf="loading"></div>

<side-nav class="master-drawer left" #drawer>
	<div class="master-drawer-inner-wrap flx-col" *ngIf="isMobile">
		<div class="master-drawer-top flx-row flx-btwn-cntr">

			<div>
				<button class="icon-button" aria-label="Strona główna" (click)="drawer.close()" [routerLink]="['/']">
					<svg class="icon icon-xl icon-home-o">
						<use xlink:href="#icon-home-o"></use>
					</svg>
				</button>
			</div>
			<div>
				<button class="icon-button" aria-label="Moje konto" matTooltip="Moje konto" i18n-matTooltip
					matTooltipPosition="below" [routerLink]="['/'+path_account]" *ngIf="isLoggedIn">
					<svg class="icon icon-xl icon-user-circle-o">
						<use xlink:href="#icon-user-circle-o"></use>
					</svg>
				</button>
				<button class="icon-button" aria-label="Zaloguj się" matTooltip="Zaloguj się" i18n-matTooltip
					matTooltipPosition="below" [routerLink]="['/'+path_login]" *ngIf="!isLoggedIn">
					<svg class="icon icon-xl icon-user-circle-o">
						<use xlink:href="#icon-user-circle-o"></use>
					</svg>
				</button>
				<button class="icon-button" aria-label="Zamknij" (click)="hideDrawer()">
					<svg class="icon icon-xl icon-close">
						<use xlink:href="#icon-close"></use>
					</svg>
				</button>
			</div>
		</div>

		<div class="mobile-navigation-wrap">
			<ul class="animation">
				<li class="navigation-head">
					<span>{{ mobilePath['shop'].length ? mobilePath['shop'][mobilePath['shop'].length-1].name : 'Menu' }}</span>
					<span *ngIf="mobilePath['shop'].length > 0" (click)="hideChildren('shop')" i18n>wstecz</span>
				</li>
				<ng-container *ngFor="let page of navigationDropdown; let i = index">
					<li *ngIf="allowed.includes(+page.visible)" [class.active]="page == pageRef">
						<span *ngIf="+page.children?.length > 0" [class.container]="page.module == 'container'" (click)="showChildren('shop', page)">{{ page.name }} 
							<svg class="icon icon-chevron-right"  *ngIf="+page.children?.length > 0">
								<use xlink:href="#icon-chevron-right"></use>
							</svg>
						</span>
						<a *ngIf="+page.children?.length == 0" [routerLink]="[page?.path]" routerLinkActive="active">{{ page.name }}</a>
					</li>
				</ng-container>
				<!-- <a *ngIf="mobilePath['shop'].length" [routerLink]="[mobilePath['shop'][mobilePath['shop'].length-1].path]" class="stroked-button w-100" i18n>wszystkie z kategorii</a> -->
			</ul>
		</div>
		<div class="master-drawer-bottom flx-row flx-btwn-cntr">

			<div *ngIf="settings" class="social w-100 flx-row flx-cntr-cntr flx-gap-default">
				<a *ngIf="settings.facebook_use_profile" [href]="settings.facebook_key_profile" target="_blank"
					rel="noopener noreferrer"><span i18n>Profil Facebook</span><svg class="icon icon-facebook-square">
						<use xlink:href="#icon-facebook-square"></use>
					</svg></a>
				<a *ngIf="settings.social_instagram_toggle" [href]="settings.social_instagram" target="_blank"
					rel="noopener noreferrer"><span i18n>Profil Instagram</span><svg class="icon icon-instagram">
						<use xlink:href="#icon-instagram"></use>
					</svg></a>
				<a *ngIf="settings.social_twitter_toggle" [href]="settings.social_twitter" target="_blank"
					rel="noopener noreferrer"><span i18n>Profil Twitter</span><svg class="icon icon-twitter-square">
						<use xlink:href="#icon-twitter-square"></use>
					</svg></a>
				<a *ngIf="settings.social_vimeo_toggle" [href]="settings.social_vimeo" target="_blank"
					rel="noopener noreferrer"><span i18n>Profil Vimeo</span><svg class="icon icon-vimeo-square">
						<use xlink:href="#icon-vimeo-square"></use>
					</svg></a>
				<a *ngIf="settings.social_pinterest_toggle" [href]="settings.social_pinterest" target="_blank"
					rel="noopener noreferrer"><span i18n>Profil Pinterest</span><svg class="icon icon-pinterest-square">
						<use xlink:href="#icon-pinterest-square"></use>
					</svg></a>
			</div>

		</div>
	</div>
</side-nav>

<side-nav class="master-cart right" #cart>
	<ng-container #cartvc></ng-container>
</side-nav>



<div #sticky class="master-header-info gutter flx-row flx-btwn-cntr">

	<div class="info-left flx-row flx-strt-cntr">
		<a href="tel:{{ settings?.website_phone }}" class="icon-link">
			<svg class="icon icon-phone available">
				<use xlink:href="#icon-phone"></use>
			</svg>
			<span class="hide-xs">{{ settings?.website_phone }}</span>
		</a>
		<p class="hide-xs">{{ settings?.website_short_contact }}</p>
	</div>

	<div class="info-right flx-row flx-gap-default flx-strt-cntr">
		<a class="icon-link" [routerLink]="['/'+path_extra_2]"><svg class="icon icon-color-swatch">
				<use xlink:href="#icon-color-swatch"></use>
			</svg> <span class="hide-xs" i18n>Strefa Architekta</span></a>
		<a class="icon-link" [routerLink]="['/'+path_extra_1]"><svg class="icon icon-cube-transparent">
				<use xlink:href="#icon-cube-transparent"></use>
			</svg> <span class="hide-xs" i18n>Współpraca B2B</span></a>
		<div class="flx-row">
			<!-- <div class="top-list-wrap" [class.visible]="currencyMenuVisible">
				<button class="icon-link" (click)="toggleCurrencyMenu()">
					<span>{{ currency | uppercase }}</span>
					<svg class="icon icon-currency">
						<use xlink:href="#icon-currency"></use>
					</svg>
				</button>
				<div class="top-list" *ngIf="currencyMenuVisible">
					<a *ngFor="let currency of currencyList" (click)="changeCurrency(currency.code)">{{ currency.label }}</a>
				</div>
			</div> -->
			<div class="top-list-wrap" [class.visible]="languageMenuVisible">
				<button class="icon-link" (click)="toggleLanguageMenu()">
					<span>{{ localeId | uppercase }}</span>
					<svg class="icon icon-globe">
						<use xlink:href="#icon-globe"></use>
					</svg>
				</button>
				<div class="top-list" *ngIf="languageMenuVisible">
					<a *ngFor="let lang of languageList" href="/{{ lang.code }}">{{ lang.label }}</a>
				</div>
			</div>
		</div>
	</div>
</div>

<header class="master-header sticky flx-col">
	<div class="master-header-wrapper gutter flx-row flx-btwn-cntr">

		<a class="master-logo" [routerLink]="['/']"> {{ pageName }} </a>
		<span class="loader-circle"></span>

		<div class="master-header-right flx-row flx-end-cntr">
			<form [class.hidden]="!searchmobile" class="search" [class.active]="search" novalidate (submit)="searchNavigate($event)">
				<input #term type="text" placeholder="Czego szukasz ?" i18n-placeholder (focus)="toggleSearch()">
				<button class="icon-button primary" aria-label="Wyszukiwarka" (click)="hideSearch()">
					<svg class="icon icon-xl icon-search">
						<use xlink:href="#icon-search"></use>
					</svg>
				</button>

				<div class="search-results flx-col" *ngIf="products.length && search">
					<div *ngFor="let element of products">
						<a [routerLink]="['/'+path_offer_details, element.path]" (click)="hideSearch()" class="flx-row flx-strt-cntr flx-gap-default">
							<img src="{{ cdn }}/products/{{ element.photos_directory }}/thumb_{{ element.photo }}"
								alt="{{ element.meta_title }}">

							<div [innerHtml]="element.title"></div>

							<app-price *appShowB2b="'show_prices'"
								[price]="element.variants[element.variant_selected].price"
								[price_promo]="element.variants[element.variant_selected].price_promo"
								[currency]="element.variants[element.variant_selected].currency"
								[quantity]="1"></app-price>

						</a>
						<hr>
					</div>
					<a [routerLink]="['/'+path_search]" [queryParams]="{title: search_term}" class="flat-button primary"
						(click)="hideSearch()" i18n>zobacz więcej</a>
				</div>
			</form>
			<div class="search-results-backdrop" *ngIf="search" (click)="hideSearch()"></div>

			<button class="hide-md icon-button hamburger" aria-label="Menu" (click)="showDrawer()">
				<svg class="icon icon-menu">
					<use xlink:href="#icon-menu"></use>
				</svg>
			</button>

			<button class="hide-md icon-button" aria-label="Wyszukiwarka" matTooltip="Wyszukiwarka" i18n-matTooltip
				matTooltipPosition="below" (click)="toggleMobileSearch()">
				<svg class="icon icon-xl icon-search">
					<use xlink:href="#icon-search"></use>
				</svg>
			</button>

			<button class="icon-button" aria-label="Ulubione" matTooltip="Ulubione" i18n-matTooltip
				matTooltipPosition="below" [routerLink]="['/'+path_favorites]">
				<svg class="icon icon-xl icon-heart-o">
					<use xlink:href="#icon-heart-o"></use>
				</svg>
			</button>

			<button class="icon-button" aria-label="Moje konto" matTooltip="Moje konto" i18n-matTooltip
				matTooltipPosition="below" [routerLink]="['/'+path_account]" *ngIf="isLoggedIn">
				<svg class="icon icon-xl icon-user-circle-o">
					<use xlink:href="#icon-user-circle-o"></use>
				</svg>
			</button>
			<button class="icon-button" aria-label="Zaloguj się" matTooltip="Zaloguj się" i18n-matTooltip
				matTooltipPosition="below" [routerLink]="['/'+path_login]" *ngIf="!isLoggedIn">
				<svg class="icon icon-xl icon-user-circle-o">
					<use xlink:href="#icon-user-circle-o"></use>
				</svg>
			</button>
			<!-- <button class="hide-xs" class="icon-button" aria-label="Ulubione" matTooltip="Ulubione" i18n-matTooltip
							matTooltipPosition="below" [routerLink]="['/'+path_account]">
							<svg class="icon icon-xl icon-heart-o"><use xlink:href="#icon-heart-o"></use></svg>
			</button> -->
			<button *appShowB2b="'allow_orders'" class="icon-button" aria-label="Koszyk" id="basket"
				(click)="showCart()">
				<span class="cart_items">{{ cartItems }}</span>
				<svg class="icon icon-xl icon-shopping-bag">
					<use xlink:href="#icon-shopping-bag"></use>
				</svg>
			</button>
		</div>
	</div>


</header>
<div *ngIf="!isMobile && navigation.length" class="navigation" (mouseleave)="toggleDropdown($event)">
	<ul class="flx-row flx-cntr-cntr flx-gap-default">
		<li *ngFor="let page of navigation; let menuIndex = index">
			<button *ngIf="+page.children?.length > 0" (click)="toggleDropdown($event, menuIndex)">{{ page.name }}</button>
			<a *ngIf="+page.children?.length == 0" [routerLink]="[page?.path]" routerLinkActive="active"
				[class.hovered]="menuIndex == currentNavigationIndex">{{ page.name }}</a>
		</li>
	</ul>

	<div *ngIf="!isMobile && navigationDropdown.length" class="master-header-drop flx-row" [class.visible]="dropdown == 'expanded'">


		<ul class="master-header-drop-main" [class.multilevel]="isDropdownMultilevel">
			<ng-container *ngFor="let page of navigationDropdown; let i = index">
				<li *ngIf="allowed.includes(+page.visible)" [class.active]="page == pageRef">
					<button *ngIf="+page.children?.length > 0" (click)="showChildren('shop', page)">{{ page.name }}</button>
					<a *ngIf="+page.children?.length == 0" [routerLink]="[page?.path]" routerLinkActive="active">{{ page.name }}</a>
				</li>
			</ng-container>
		</ul>

		<ul class="master-header-drop-secondary flx-row" *ngIf="isDropdownMultilevel">
			<ng-template #recursiveList let-navigation>
				<ng-container *ngFor="let page of navigation; let j = index">
					<li *ngIf="allowed.includes(+page.visible)">
						<button *ngIf="page.module == 'container'">{{ page.name }}</button>
						<a *ngIf="+page.children?.length == 0" [routerLink]="[page?.path]">{{ page.name }}</a>
						<ul *ngIf="page.children">
							<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: page.children }">
							</ng-container>
						</ul>
					</li>

				</ng-container>
			</ng-template>
			<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: navigationDropdownRecursive }">
			</ng-container>
		</ul>

		<div class="navigation-image-wrap flx-col" *ngIf="pageRef">
			<a [routerLink]="[pageRef?.path]" class="square-wrap" *ngIf="pageRef.extra_field_3">
				<app-image [natural]="false"
					[url]="cdn +'/pages/'+ pageRef.photos_directory + '/' + pageRef.extra_field_3"></app-image>
			</a>
			<a [routerLink]="[pageRef?.path]" class="flat-button primary fat" i18n>wszystkie z kategorii</a>
		</div>


	</div>

</div>

<div class="breadcrumb-wrap gutter" *ngIf="!hideBreadcrumbs">
	<ul class="breadcrumb">
		<li><a [routerLink]="['/']" class="breadcrumb" i18n>Strona główna</a> <svg class="icon icon-chevron-right">
				<use xlink:href="#icon-chevron-right"></use>
			</svg></li>
		<li *ngFor="let breadcrumb of breadcrumbs">
			<a *ngIf="breadcrumb.path != 'none'" [routerLink]="[breadcrumb.path]">
				{{ breadcrumb.label }}
			</a>
			<span *ngIf="breadcrumb.path == 'none'">
				{{ breadcrumb.label }}
			</span>
			<svg class="icon icon-chevron-right">
				<use xlink:href="#icon-chevron-right"></use>
			</svg>
		</li>
	</ul>
</div>
<div class="master-router">
	<router-outlet></router-outlet>
</div>
<ng-container #footervc></ng-container>
<fb-chat *ngIf="isChat"></fb-chat>
<!-- <button class="flat-button primary gotop" (click)="goTop()"><svg class="icon icon-chevron-up"><use xlink:href="#icon-chevron-up"></use></svg></button> -->