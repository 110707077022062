import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotfoundRoutingModule } from './notfound-routing.module';
import { NotfoundComponent } from './notfound.component';
import { SharedModule } from '../shared/shared.module';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [CommonModule, NotfoundRoutingModule, SharedModule, SwiperModule],
  declarations: [NotfoundComponent],
  exports: [NotfoundComponent],
})

export class NotfoundModule { }
