import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotfoundComponent } from './errors/notfound.component';
import { environment } from './../environments/environment';
import { AuthGuard } from './shared/auth/auth-guard.service';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: {
          animation: 'HomeModule',
          id: -979,
          name: '',
          title: $localize `Strona główna`,
          content: '',
          description: '',
          keywords: '',
          canonical: environment.base,
          hideBredcrumbs: true
        }
      },
      {path: $localize `zaloguj`, loadChildren: () => import('./auth/login.module').then(m => m.LoginModule), data: {
        id: -980,
        name: $localize `Logowanie`,
        title: $localize `Logowanie`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/zaloguj'
      }},
      {path: $localize `przypomnij-haslo`, loadChildren: () => import('./auth/password.module').then(m => m.PasswordModule), data: {
        id: -981,
        name: $localize `Przypominj hasło`,
        title: $localize `Przypominj hasło`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/przypomnij-haslo'
      }},
      {path:  $localize `rejestracja`, loadChildren: () => import('./auth/register.module').then(m => m.RegisterModule), data: {
        id: -982,
        name: $localize `Rejestracja`,
        title: $localize `Rejestracja`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/rejestracja'
      }},
      {path:  $localize `rejestracja`+'/:type', loadChildren: () => import('./auth/register.module').then(m => m.RegisterModule), data: {
        id: -983,
        name: $localize `Rejestracja`,
        title: $localize `Rejestracja`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/rejestracja'
      }},
      {path:  $localize `wyloguj`, loadChildren: () => import('./auth/logout.module').then(m => m.LogoutModule), data: {
        id: -984,
        name: $localize `Wyloguj`,
        title: $localize `Wyloguj`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/wyloguj'
      }},
      {path:  $localize `konto`, loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard], data: {
        id: -991,
        name: $localize `Twoje konto`,
        title: $localize `Twoje konto`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `konto/profil`, loadChildren: () => import('./user/user-details.module').then(m => m.UserDetailsModule), canActivate: [AuthGuard], data: {
        id: -992,
        name: $localize `Twoje dane`,
        title: $localize `Twoje dane`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `konto/zamowienia`, loadChildren: () => import('./user/user-orders.module').then(m => m.UserOrdersModule), canActivate: [AuthGuard], data: {
        id: -993,
        name: $localize `Twoje zamówienia`,
        title: $localize `Twoje zamówienia`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `konto/pracownicy`, loadChildren: () => import('./user/user-users.module').then(m => m.UserUsersModule), canActivate: [AuthGuard], data: {
        id: -1003,
        name: $localize `Pracownicy`,
        title: $localize `Pracownicy`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `konto/pliki`, loadChildren: () => import('./user/user-files.module').then(m => m.UserFilesModule), canActivate: [AuthGuard], data: {
        id: -1004,
        name: $localize `Pliki`,
        title: $localize `Pliki`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `konto/zwroty`, loadChildren: () => import('./user/user-returns.module').then(m => m.UserReturnsModule), canActivate: [AuthGuard], data: {
        id: -994,
        name: $localize `Twoje zwroty`,
        title: $localize `Twoje zwroty`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `konto/rabaty`, loadChildren: () => import('./user/user-discounts.module').then(m => m.UserDiscountsModule), canActivate: [AuthGuard], data: {
        id: -995,
        name: $localize `Twoje rabaty`,
        title: $localize `Twoje rabaty`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `konto/faktury`, loadChildren: () => import('./user/user-documents.module').then(m => m.UserDocumentsModule), canActivate: [AuthGuard], data: {
        id: -1002,
        name: $localize `Twoje faktury`,
        title: $localize `Twoje faktury`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `schowek`, loadChildren: () => import('./user/user-favorites.module').then(m => m.UserFavoritesModule), data: {
        id: -1005,
        name: $localize `Schowek`,
        title: $localize `Schowek`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/konto'
      }},
      {path:  $localize `kasa`, loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule), data: {
        id: -997,
        name: $localize `Kasa`,
        title: $localize `Kasa`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/kasa'
      }},
      {path:  $localize `platnosc`, loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule), data: {
        id: -998,
        name: $localize `Płatności`,
        title: $localize `Płatności`,
        content: '',
        description: '',
        keywords: ''
      }},
      {path:  $localize `dziekujemy`, loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule), data: {
        id: -999,
        name: $localize `Dziękujemy za płatność`,
        title: $localize `Dziękujemy za płatność`,
        content: '',
        description: '',
        keywords: ''
      }},
      {path:  $localize `promocje`+'/:path', loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule), data: {
        id: -1000,
        name: $localize `Produkty w promocji`,
        title: $localize `Produkty w promocji`,
        content: '',
        description: '',
        keywords: 'produkty w promocji',
        canonical: environment.base + '/promocje'
      }},
      {path: 'order/:path/:token', loadChildren: () => import('./confirmations/confirmations.module').then(m => m.ConfirmationsModule), data: {
        id: -1001,
        name: $localize `Potwierdzenie zamówienia`,
        title: $localize `Potwierdzenie zamówienia`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/order',
        hideBredcrumbs: true, 
      }},
      {path: 'reset/:path/:token', loadChildren: () => import('./confirmations/confirmations.module').then(m => m.ConfirmationsModule), data: {
        id: -1002,
        name: $localize `Resetowanie hasła`,
        title: $localize `Resetowanie hasła`,
        content: '',
        description: '',
        keywords: '',
        canonical: environment.base + '/reset',
        hideBredcrumbs: true, 
      }},
      { path:  '404',  component: NotfoundComponent, data: { id: -905, title: '404', content: '', description: '', keywords: '' } },
      { path: '**', redirectTo: '/404', data: { id: -1, title: '', content: '', description: '', keywords: '' } }
    ], { initialNavigation: 'enabledNonBlocking', scrollPositionRestoration: 'enabled', enableTracing: false, anchorScrolling: 'enabled' })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
