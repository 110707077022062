export const environment = {
  production: true,
  isServer: true,
  version: 1.0,
  // for prerender

  host: 'https://evolutionhome.pl',
  cdn: 'https://api.evolutionhome.pl/uploads',
  api: 'https://api.evolutionhome.pl/app',
  socket: 'https://peer.digitalgrid.pl',
  base: 'https://evolutionhome.pl',
  name: 'Evolution Home',
  currencies: ['PLN', 'EUR'],
  withavatar: false,
  product_extras: [],
  client_extras: [],
  countries: [
    {value: 'AD', viewValue: $localize `Andora`},
    {value: 'AT', viewValue: $localize `Austria`},
    {value: 'BE', viewValue: $localize `Belgia`},
    {value: 'BA', viewValue: $localize `Bośnia i Hercegowina`},
    {value: 'BG', viewValue: $localize `Bułgaria`},
    {value: 'HR', viewValue: $localize `Chorwacja`},
    {value: 'CY', viewValue: $localize `Cypr`},
    {value: 'ME', viewValue: $localize `Czarnogóra`},
    {value: 'CZ', viewValue: $localize `Czechy`},
    {value: 'DK', viewValue: $localize `Dania`},
    {value: 'EE', viewValue: $localize `Estonia`},
    {value: 'FI', viewValue: $localize `Finlandia`},
    {value: 'FR', viewValue: $localize `Francja`},
    {value: 'GI', viewValue: $localize `Gibraltar`},
    {value: 'GR', viewValue: $localize `Grecja`},
    {value: 'ES', viewValue: $localize `Hiszpania`},
    {value: 'NL', viewValue: $localize `Holandia`},
    {value: 'IE', viewValue: $localize `Irlandia`},
    {value: 'IS', viewValue: $localize `Islandia`},
    {value: 'LI', viewValue: $localize `Liechtenstein`},
    {value: 'LT', viewValue: $localize `Litwa`},
    {value: 'LU', viewValue: $localize `Luksemburg`},
    {value: 'LV', viewValue: $localize `Łotwa`},
    {value: 'MK', viewValue: $localize `Macedonia`},
    {value: 'MT', viewValue: $localize `Malta`},
    {value: 'DE', viewValue: $localize `Niemcy`},
    {value: 'NO', viewValue: $localize `Norwegia`},
    {value: 'PL', viewValue: $localize `Polska`},
    {value: 'PT', viewValue: $localize `Portugalia`},
    {value: 'RU', viewValue: $localize `Rosja`},
    {value: 'RO', viewValue: $localize `Rumunia`},
    {value: 'RS', viewValue: $localize `Serbia`},
    {value: 'SK', viewValue: $localize `Słowacja`},
    {value: 'SI', viewValue: $localize `Słowenia`},
    {value: 'CH', viewValue: $localize `Szwajcaria`},
    {value: 'SE', viewValue: $localize `Szwecja`},
    {value: 'UA', viewValue: $localize `Ukraina`},
    {value: 'HU', viewValue: $localize `Węgry`},
    {value: 'GB', viewValue: $localize `Wielka Brytania`},
    {value: 'IT', viewValue: $localize `Włochy`},
  ],
  inpost_src: 'https://geowidget.inpost.pl/inpost-geowidget.js',
  inpost_css: 'https://geowidget.inpost.pl/inpost-geowidget.css',
  inpost: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjE5OTUwMTYzMDksImlhdCI6MTY3OTY1NjMwOSwianRpIjoiZGE0NTVmMTItMTk2Yy00ODliLWI3MzgtNzk5ZGVjNGI1NzNlIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTpnekxDY1RyZUVBbEZDMDNUUWFxdjRmU3FUME41YVhXcFFUcEZ4R0ZSVnJJIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiNWNmM2JlYzEtYWI5ZS00OTk4LWJjNzctYmQxYjNiMjUwMGUxIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjVjZjNiZWMxLWFiOWUtNDk5OC1iYzc3LWJkMWIzYjI1MDBlMSIsImFsbG93ZWRfcmVmZXJyZXJzIjoiZXZvbHV0aW9uaG9tZS5wbCIsInV1aWQiOiIwNDMyYTVhOS05NTA3LTQ5Y2MtYmY5Mi1hYzE2NDhmOTgyNDUifQ.FVRaBHn3HK4-TdhRcvenIacJsphVdtzBOAVU3eZxA9H-Fv0cac6heG1jRHIG6zdLZbHfBSJhUNbiAAy7RqJJFHoQviBacx6co2VxKpyHhNoyJAA5SkVV_wzqo5NgPg0FLUfKesU07ZkJqpe_hKEv8ISmSulY7FMGjdSRNhm2crsRB1w9bpXSugqqqyNsdXyT9fPQPIyWYs8PNlsW6mzw1Ph5ho6uKCC4LkhmXEjEobEpA6VFk57OiCoZ5pB3vGfmIx-a2wB3lBAL4L3ifUOaYMOQReCilhuLmkPEDfVLNCjTICuPe4Crs2pSyPn2f4AasZQCX-T81kBmbakA3utDbg'
};
