import { Page } from '../navigation/pages.model';



export const utils = {

    filterData(data: any[], modifier: string, type: string, section: string, id = 0, parent_id = 0) {

        if (parent_id === 1) {
            parent_id = 0; // reset to 0 on top level or we get all top level items via parent id
        }

        var r = data.filter((page: Page) =>  {
            if (page.children) page.children = this.filterData(page.children, modifier, type, section, id, parent_id);
            if (modifier === '!==') {
                return (page.module !== type && +page.visible === 1 && page.visible_in.includes(section))
            } else if (modifier === '===') {
                return (page.module === type && +page.visible === 1 && page.visible_in.includes(section))
            } else if (id > 0 && section){  // in section
                return ((+page.parent_id === id || +page.id === id || +page.parent_id === parent_id || +page.id === parent_id) && +page.visible === 1 && page.visible_in.includes(section))
            } else if (id > 0 && !section){ // whatever
                return ((+page.parent_id === id || +page.id === id || +page.parent_id === parent_id || +page.id === parent_id) && +page.visible === 1)
            }
        })
        return r;
    },

    calculateDiscountPrice(discounts: any[], category: any[], manufacturer: number, tax: number,  price_promo: number,  price: number, id: number, cart = false) {

        tax = price * (tax / 100);
        let modprice_old = 0;
        let discounted = false;
        let modprice = Math.round((+price + +tax) * 100) / 100;
        // modprice = Math.round(modprice * 100) / 100;

        if (discounts) {
            let last_discount_value = 0;
            console.log('---------------------');
            console.log(discounts);

            discounts.forEach(discount => {
              let d_val = 0;
              if (discount.type === 'percent') {
                if (discount.type_calculation === 'cascade' && discount.code) {
                  const discountPrice = +modprice - last_discount_value;
                  d_val = discountPrice * (+discount.value / 100);
                } else {
                  d_val = +modprice * (+discount.value / 100);
                }
                d_val = Math.round( d_val * 100 + Number.EPSILON ) / 100;
              }  else if (discount.type === 'amount') {
                d_val = +discount.value;
              }
      
              if (discount.code && (discount.type_calculation === 'add' || discount.type_calculation === 'cascade') && cart) { //if code, sum with previous
                // console.log(discount.code, discount.type_calculation, this.cart, d_val, last_discount_value);
                d_val = last_discount_value + d_val;
                modprice_old =  modprice - d_val;
                last_discount_value = d_val;
                discounted = true;
              }
      
              if (discount.products_id) {
                // PRODUCT DISCOUNT
                const discount_products = discount.products_id.split(',').map( Number ) || undefined;
                const result = discount_products.includes(+id);
                if (result) {
                    if (last_discount_value < d_val) {
                      modprice_old =  modprice - d_val;
                      last_discount_value = d_val;
                      discounted = true;
                      // console.log('product', last_discount_value, d_val);
                    }
                }
              } 
              if (discount.categories_id && category) {
                // // CATEGORY DISCOUNT
                const discount_categories = discount.categories_id.split(',').map( Number ) || undefined;
                const result = category.some( (e: any) => discount_categories.includes(+e.id_page) );
                if (result) {
                    if (last_discount_value < d_val) {
                      modprice_old =  modprice - d_val;
                      last_discount_value = d_val;
                      discounted = true;
                      // console.log('kategoria', last_discount_value, d_val);
                    }
                }
              } 
              if (discount.manufacturers_id) {
                // MANUFACTURER DISCOUNT
                const discount_manufacturers = discount.manufacturers_id.split(',').map( Number ) || undefined;
                if (discount_manufacturers.includes(+manufacturer)) {
                    if (last_discount_value < d_val) {
                      modprice_old = modprice - d_val;
                      last_discount_value = d_val;
                      discounted = true;
                      // console.log('producent', last_discount_value, d_val);
                    }
                }
              }
             
              modprice_old = Math.round(modprice_old * 100) / 100;
            });
            // console.log('---------------------');
          }

          let final = (modprice_old ? modprice_old : modprice);
          return final;//Math.round(final * 10) / 10;
    },

    scrollTo(to: number, callback: any, duration: number) {
        // because it's so fucking difficult to detect the scrolling element, just move them all
        function move(amount) {
            document.documentElement.scrollTop = amount;
            document.body.scrollTop = amount;
        }
        function position() {
            return document.documentElement.scrollTop  || document.body.scrollTop;
        }

        var requestAnimFrame = (function(){
            return  window.requestAnimationFrame || function( callback ){ window.setTimeout(callback, 1000 / 60); };
        })();

        var start = position(),
            change = to - start,
            currentTime = 0,
            increment = 20;
        duration = (typeof(duration) === 'undefined') ? 500 : duration;
        var animateScroll = function() {
            // increment the time
            currentTime += increment;
            // find the value with the quadratic in-out easing function
            var val = utils.easeInOutQuad(currentTime, start, change, duration);
            // move the document.body
            move(val);
            // do the animation unless its over
            if (currentTime < duration) {
                requestAnimFrame(animateScroll);
            } else {
            if (callback && typeof(callback) === 'function') {
                // the animation is done so lets callback
                callback();
            }
            }
        };
        animateScroll();
    },

    easeInOutQuad (t, b, c, d) {
        t /= d/2;
        if (t < 1) {
            return c/2*t*t + b
        }
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    },
};

