import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, Inject, PLATFORM_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Discount } from '../cart/cart.model';
import { utils } from './utils';

@Component({
  selector: 'app-price',
  templateUrl: 'price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PriceComponent implements OnChanges {

  @Input() public id: number;
  @Input() public price: number;
  @Input() public price_promo: number;
  @Input() public tax: number;
  @Input() public currency: string;
  @Input() public cart = false;
  @Input() public quantity: number;
  @Input() public discounts: Discount[];
  @Input() public category: any = [];
  @Input() public manufacturer: any;
  @Input() public series: string;
  @Input() public change: number;
  public modprice = 0;
  public modprice_old = 0;
  public discounted = false;
  public discount_value = 0;
  public userId = null;
  public userGroups = null;
  public priceCurrency = 'PLN';


  constructor(@Inject(PLATFORM_ID) private platformId: Object,  @Inject(DEFAULT_CURRENCY_CODE) private currencyDefault: string, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    // this.ngOnInit();
    // if (!this.cart) {
      // console.log('INIT', this.cart, this.discounts, this.category, this.manufacturer);
    // }

    this.priceCurrency = this.currencyDefault;
    if (this.currency) {
      this.priceCurrency = this.currency;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('user_id')) {
        this.userId = +localStorage.getItem('user_id');
        if (this.priceCurrency === 'EUR' && !this.userId) {
          this.tax = 23;
        }
        if (localStorage.getItem('user_groups')) {
        this.userGroups = localStorage.getItem('user_groups').split(',').map( Number );
        }
      }
      
    }

  
    this.discounted = false;
    const tax = this.price * (this.tax / 100);
    this.modprice = Math.round((+this.price + +tax) * 100) / 100;
    if (this.id > -1) {
      if (this.discounts) {
        const discounted_price = utils.calculateDiscountPrice(this.discounts, this.category, this.manufacturer, 0, this.price_promo, this.price, this.id, this.cart);
        if (discounted_price < this.price) {
          this.discounted = true;
          const discounted_tax = discounted_price * (this.tax / 100);
          this.modprice_old = Math.round((discounted_price + discounted_tax) * 100) / 100
        }
      } //discounts
      
    }
    // || +this.price_promo < this.modprice_old
    if (+this.price_promo > 0 && +this.price_promo < +this.price) {
      const tax_promo = this.price_promo * (this.tax / 100);
      this.modprice_old = Math.round((+this.price_promo + +tax_promo) * 100) / 100;
      this.discounted = true;
    }
  }

}
