import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService, UserState } from './auth.service';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

 constructor(private auth: AuthService, private router: Router) {}

 canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isLoggedIn.pipe(
      take(1),
      map((state: UserState) => {
        if (!state.logged) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      }));
  }
}
