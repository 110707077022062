import { Location } from "@angular/common";
import { Component, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent {

  @HostBinding('class.open') @Input() isOpen: boolean = false;
  @Input() enabled: boolean = true;
  @Input() extraClasses: string = '';
  @Input() title: string;


  // @HostBinding('class.open') b2b: boolean = false;
  // @HostBinding('class.small') small: boolean = false;
  // @HostBinding('class.loading-content') loading: boolean = false;

  allListeners: (() => void)[] = [];

  constructor(private location: Location,
    private renderer: Renderer2,
    private elementRef: ElementRef) { }

  open() {
    this.isOpen = true;
    // window.addEventListener('popstate', this.onHardwareBackButton, { passive: true });
    // this.location.go('side-nav');

    this.renderer.addClass(document.body, 'disabled');
    // setTimeout(() => {
    //   const myComponent = this.elementRef.nativeElement as HTMLElement;
    //   const clickableElements = myComponent.querySelectorAll("li, a, button");

    //   clickableElements.forEach(eachElem => {
    //     const listener = this.renderer.listen(eachElem, "click", () => {
    //       this.close();
    //     });
    //     this.allListeners.push(listener);
    //   });
    // }, 400);

  }

  close(goBack: boolean = false) {
    this.isOpen = false;
    // window.removeEventListener('popstate', this.onHardwareBackButton);
    this.renderer.removeClass(document.body, 'disabled');
    // if (goBack) {
    //   window.history.back();
    // }
    // this.allListeners.forEach(listener => listener())
  }

  // onHardwareBackButton = () => {
  //   this.close();
  //   window.removeEventListener('popstate', this.onHardwareBackButton);
  // };
}