import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import { Products, Product, Filter, Collections } from './offer.model';
import { environment } from './../../environments/environment';

@Injectable()
export class OfferService {

  product = new Subject<Product>();
  dataChange: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  get data(): Product[] { return this.dataChange.value; }

  constructor(private httpClient: HttpClient) {}

  items(query: any): Observable<Products> {
    return this.httpClient.post<Products>(environment.api + '/products', query);
  }

  collections(query: any): Observable<Collections> {
    return this.httpClient.post<Collections>(environment.api + '/collections', query);
  }

  filters(category: number): Observable<Filter[]> {
    return this.httpClient.get<Filter[]>(environment.api + '/filters/' + category);
  }

  item(path: string): Observable<Product> {
    return this.httpClient.get<Product>(environment.api + '/product/' + path);
  }

}

