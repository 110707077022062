import { ViewContainerRef, Inject, Injectable, ApplicationRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';


import { ModalDialogComponent } from './modal.component';
import { IModalDialogOptions } from './modal.interface';
import { ModalDialogInstanceService } from './modal-instance.service';
import { AppComponent } from 'src/app/app.component';


@Injectable()
export class UtilsService {

  public isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoadingBool = true;

  constructor(@Inject(ModalDialogInstanceService) private modalDialogInstanceService: ModalDialogInstanceService, private appRef: ApplicationRef) {
  }

  get isLoading(): Observable<boolean> {
    return this.isLoadingSubject.asObservable().pipe(share());
  }

  public setLoading() {
    if (!this.isLoadingBool) {
      this.isLoadingBool = true;
      this.isLoadingSubject.next(true);
    }
  }

  public clearLoading() {
    if (this.isLoadingBool) {
      setTimeout(() => {
        this.isLoadingBool = false;
        this.isLoadingSubject.next(false);
      });
    }
  }

  openDialog(options: Partial<IModalDialogOptions<any>> = {}, target: ViewContainerRef = null) {
    setTimeout(() => {
      if (!options.placeOnTop) {
        this.modalDialogInstanceService.closeAnyExistingModalDialog();
      }
      if (target == null) {
        target = (this.appRef.components[0].instance as AppComponent).viewRef;
      } 
      const componentRef = target.createComponent(ModalDialogComponent);
      componentRef.instance.dialogInit(componentRef, options);

      this.modalDialogInstanceService.saveExistingModalDialog(componentRef);
    }, 0);
  }

}

