<div class="badges" *ngIf="type !== 'small'">
	<span *ngIf="+product.delivery === 3" class="badge fastdelivery">48h</span>
	<span *ngIf="+product.price_promo" class="badge discount" i18n>promocja</span>
	<span *ngIf="+product.day" class="badge feat" i18n>polecamy</span>
	<span *ngIf="+product.bestseller" class="badge bestseller" i18n>bestseller</span>
	<span *ngIf="+product.new" class="badge new" i18n>nowość</span>
</div>

<div class="favorite flx-col flx-gap-half">
	<button *ngIf="type != 'favorite'" class="icon-button" aria-label="Do ulubionych" (click)="addToFavorites(product.variants[product.variant_selected].id)"><svg class="icon icon-xl icon-heart-o"><use xlink:href="#icon-heart-o"></use></svg></button>
	<label  *ngIf="type == 'favorite'"class="checkbox"><input type="checkbox" (click)="selectProduct(product.variants[product.variant_selected].id)"/></label>
	<button *ngIf="type == 'favorite'" class="icon-button" aria-label="Do ulubionych" (click)="removeFromFavorites(product.variants[product.variant_selected].id)"><svg class="icon"><use xlink:href="#icon-trash"></use></svg></button>	
</div>

<a (click)="navigate()" (click)="navigate()" [routerLink]="link" [queryParams]="params" aria-label="Produkt" class="product-image">
	<app-image *ngIf="product.id > 0" [url]="cdn+'/products/'+product.photos_directory+'/medium_'+product.photo"></app-image>
</a>
<div class="card-product-content-wrap">
<div class="card-product-content">
	<a *appShowB2b="'show_prices'" (click)="navigate()" [routerLink]="link" [queryParams]="params">
		<app-price
		[id]="product.id"
		[price]="product.variants[product.variant_selected].price"
		[price_promo]="product.variants[product.variant_selected].price_promo"
		[quantity]="1"
		[tax]="product.variants[product.variant_selected].tax"
		[discounts]="discounts"
		[manufacturer]="product.id_manufacturer"
		[series]="product.series"
		[category]="product.categories"
		[currency]="product.variants[product.variant_selected].currency"></app-price>
	</a>
	<a (click)="navigate()" (click)="navigate()" [routerLink]="link" [queryParams]="params">
		<h3 [innerHtml]="product.title"></h3>
		<p [innerHtml]="product.series"></p>
	</a> 	
</div>
</div>
<div *appShowB2b="'allow_orders'" class="card-product-actions">
	<div>
		<button class="stroked-button btn-quantity" (click)="minus()"><svg class="icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>
		<input class="input-quantity" [value]="quantity" disabled>
		<button class="stroked-button btn-quantity" (click)="plus()"><svg class="icon icon-plus"><use xlink:href="#icon-plus"></use></svg></button>
	</div>
	<button class="flat-button primary fat" (click)="addToCart()" i18n>DO KOSZYKA</button>
</div>
