export class Cart {
  id: number;
  id_carrier: number;
  id_payment: number;
  id_address_delivery: number;
  id_address_invoice: number;
  vat: number;
  currency: string;
  id_client: number;
  code: string;
  data: string;
  comment_shop: string;
  comment_carrier: string;
  self_pickup: number;
  ommit_availability: boolean;
  // date_created: string;
  // date_updated: string;
  products: CartProduct[];
  discount: Discount[];
  error: number;
  addresses?: any[];
}

export class CartProduct {
  id: number;
  id_cart: number;
  id_product: number;
  id_product_variant: number;
  id_configuration: string;
  title: string;
  series: string;
  quantity: number;
  width: number;
  height: number;
  length: number;
  dimension_units: number;
  weight: number;
  weight_units: number;
  price: number;
  price_promo: number;
  custom_price: number;
  tax: number;
  attr_name: string;
  categories: any[];
  categories_names: any[];
  manufacturer: string;
  manufacturer_id: number;
  sku: string;
  availability: number;
  availability_txt: string;
  extra_1: string;
  extra_2: string;
  extra_3: string;
  extra_4: string;
  attributes: any;
  state: string; // for animation

  discounts: Discount[];
  discounted_price: number;
  discounted_price_value: string;
  discounted_price_code: string;
  // discount_applied: number[];
  // discount_categories: any[];
  // discount_type: string[];
  // date_created: string;
}

export class Discounts {
  items: Discount[];
  pages: any;
}

export class Discount {
  id: number;
  photos_directory: string;
  name: string;
  path: string;
  description: string;
  code: string;
  valid_from: string;
  valid_to: string;
  total_uses: string;
  total_user: string;
  type: string;
  type_calculation: string;
  visible: number;
  value: number;
  categories: string;
  categories_id: string;
  manufacturers: string;
  manufacturers_id: string;
  products: string;
  products_id: string;
  series: string;
  clients: string;
  clients_id: string;
  groups: string;
  groups_id: string;
  affiliated_products: any;
  photos: any;
}
