import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Renderer2, Inject, PLATFORM_ID, LOCALE_ID, NgZone, DEFAULT_CURRENCY_CODE, ElementRef, AfterViewInit, ViewContainerRef, HostBinding } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, Event as RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuthService, UserState } from './shared/auth/auth.service';
import { CartService } from './shared/cart/cart.service';
import { UtilsService } from './shared/services/utils.service';
import { NavigationService } from './shared/navigation/navigation.service';
import { Page, Settings } from './shared/navigation/pages.model';
import { OfferService } from './offer/offer.service';
import { Product } from './offer/offer.model';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, skip } from 'rxjs/operators';
import { UpdateService } from './update.service';
import { utils } from './shared/helpers/utils';
import { environment } from '../environments/environment';
import { Client } from './auth/auth.model';
import { UserService } from './user/user.service';
import { SideNavComponent } from './shared/sidenav/side-nav.component';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  // animations: [
  //   trigger('.disabled', [])
  // ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnInit, AfterViewInit {
  // @HostBinding('@.disabled') public disabled = false;

  @ViewChild('drawer') mainDrawer: SideNavComponent;
  @ViewChild('cart') cartDrawer: SideNavComponent;
  @ViewChild('cartvc', {read: ViewContainerRef}) cartvc: ViewContainerRef;
  @ViewChild('footervc', {read: ViewContainerRef}) footervc: ViewContainerRef;
  @ViewChild('refreshForm', { static: false }) refreshForm;

  public pageName = environment.name;
  public languageMenuVisible = false;
  public languageList = [
    { code: '', label: 'Polski' },
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français' },
    { code: 'de', label: 'Deutsch' },
  ];
  public currencyMenuVisible = false;
  public currencyList = [
    { code: 'PLN', label: 'PLN' },
    { code: 'EUR', label: 'EUR' },
    // { code: 'USD', label: 'USD' },
  ];
  public hideBreadcrumbs = true;
  public isLoggedIn = false;
  public isMobile = false;
  public isChat = true;
  public allowed = [1];
  public mobilePath = {shop: [], footer: []};
  public mobileMenuSectionHeight = {shop: [0], footer: [0]};
  public dropdown = 'collapsed';
  public dropdownMenuIndex = -1;
  public navMode = 'over';
  public navModeOpened = false;
  public last_activated_route = null;
  public currentNavigationIndex = -1;
  public navigation: Page[] = [];
  // public navigationShop: Page[] = [];
  public navigationFooter: Page[] = [];
  public isDropdownMultilevel = false;
  public navigationDropdown: Page[] = [];
  public navigationDropdownRecursive: Page[] = [];
  public pageRef: Page;
  public loading = false;
  public cartItems = 0;
  public cartInited = false;
  public cdn = environment.cdn;
  public base = environment.base;
  public search = false;
  public searchmobile = false;
  public search_term = '';
  public settings: Settings;
  public cookie_visible = true;
  public cookie_content = '';
  public products: Product[] = [];
  public initialized = false;
  public breadcrumbs = [];
  public delivery = '';
  public withcode = false;
  public prices_disabled = false;
  public shopping_disabled = false;

  public path_account = 'konto';
  public path_login = 'zaloguj';
  public path_favorites = 'schowek';
  public path_search = '/';
  public path_offer_details = '/';
  public path_extra_1 = '/';
  public path_extra_2 = '/';

  @ViewChild('term', {static: false}) term: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(LOCALE_ID) public localeId: string, @Inject(DEFAULT_CURRENCY_CODE) public currency: string, 
    private router: Router, private activatedRoute: ActivatedRoute, private zone: NgZone, private auth: AuthService, private cartService: CartService, private utilsService: UtilsService,
    private navigationService: NavigationService, private renderer: Renderer2, private offerService: OfferService, private usersService: UserService, 
    private cdr: ChangeDetectorRef, private breakpointObserver: BreakpointObserver, private update: UpdateService, public viewRef: ViewContainerRef) {
    if (isPlatformBrowser(this.platformId)) {
      utilsService.setLoading();

      this.breakpointObserver
        .observe(['(min-width: 1279px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isMobile = false;
            this.searchmobile = true;
            this.navigationService.mobile.next(false);
            this.cdr.markForCheck();
            // Viewport is 500px or over!
          } else {
            this.isMobile = true;
            this.navigationService.mobile.next(true);
            this.searchmobile = false;
            this.cdr.markForCheck();
            // Viewport is getting smaller!
          }
        });

      this.router.events.subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event, activatedRoute);
      });
      this.navigationService.currentPage.subscribe(this.dynamicPageChanged); // push product or article detail

      this.auth.isLoggedIn.subscribe((state: UserState) => {
        this.isLoggedIn = state.logged;

        if (state) {
          this.allowed = [1,2];
        } else {
          this.allowed = [1];
        }

        if (this.isLoggedIn && isPlatformBrowser(this.platformId)) {
          const userId = +localStorage.getItem('user_id');
          const userRole = localStorage.getItem('user_role');
          const currency = localStorage.getItem('user_currency');
          const userState = localStorage.getItem('user_state') || 'confirmed';
          this.cartService.fetchDiscounts({type: 3, client: userId});
          if (userState == 'unconfirmed') {

            const dialogResponse = new Subject<any>();

            dialogResponse.subscribe(data => {
              let client = new Client();
              client.id = userId;
              client.state = 1;
              client.confirmations = JSON.stringify(data);
              this.usersService.change(client).pipe(take(1)).subscribe(result => {
                this.utilsService.openDialog({
                  isSnackbar: 'success',
                  content: $localize `Twoje preferencje zostały zapisane`,
                  actionButtons: [{ text: 'OK', onAction: () => true}],
                }, this.viewRef);

                localStorage.removeItem('user_state');
              });
            });

            this.utilsService.openDialog({
              childComponent: 'LazyUserConfirmComponent',
              closeDialogSubject: dialogResponse
            }, this.viewRef);

          }
        } else {
          this.cartService.clearDiscounts();
          localStorage.removeItem('user_token');
          localStorage.removeItem('user_id');
          localStorage.removeItem('user_role');
          localStorage.removeItem('user_state');
          localStorage.removeItem('user_currency');
          this.cartService.fetchDiscounts({ type:0 });
        }
        this.cdr.markForCheck();
      });

      this.utilsService.isLoading.subscribe((state: boolean) => {
        this.loading = state;
        this.cdr.markForCheck();
      });


      this.navigationService.code.subscribe(this.codeChanged);
      this.cartService.cartItems.pipe(skip(1)).subscribe(this.cartChanged);
    }
  }

  navigationInterceptor = (event: RouterEvent, activatedRoute: ActivatedRoute) => {

    if (event instanceof NavigationStart) {
      this.last_activated_route = activatedRoute.snapshot?.firstChild?.url || '';
      this.utilsService.setLoading();
    }

    if (event instanceof NavigationEnd) {

      this.breadcrumbs = [];
      activatedRoute.firstChild.url.forEach((url) => {

        const routeData = activatedRoute.firstChild.snapshot.data;
        if (routeData.hideBredcrumbs) {
        this.hideBreadcrumbs = true;
        } else {
        this.hideBreadcrumbs = false;
        }
        if (routeData.hideChat) {
        this.isChat = false;
        }else {
        this.isChat = true;
        }
        let last = '';
        for (const part of url) {
          const path = last + part.path;
          const crumb = this.router.config.find(x => x.path === path);
          if (crumb) {
            if (crumb.data['name']) this.breadcrumbs.push({label: crumb.data['name'], path: path});
          }
          last = path + '/';
        }

        // set meta to every other page than home
        this.navigationService.setMeta(routeData.title,
          routeData.keywords,
          routeData.description,
          routeData.canonical
        );

        
        this.closeDropdown();
        this.hideDrawer();
      });

      this.utilsService.clearLoading();
    }
  }

  ngOnInit() {

    this.navigationService.navigation.subscribe((setup: any) => {
      this.settings = setup.settings;
      this.navigation = setup.menu;
      this.navigationFooter = setup.footer;
      this.cookie_visible = !!+this.settings.cookies_use;
      this.cookie_content = this.settings.cookies_message;
      this.shopping_disabled = !this.settings.b2b_retail;
      this.prices_disabled = !this.settings.b2b_retail_price;

      //setup offer url
      this.path_offer_details = this.router.config.find(x => x.data?.module === 'OfferDetailsModule')?.data.path || '/';
      this.path_search = this.router.config.find(x => x.data?.module === 'OfferModule' && x.data.level == 0)?.path || '/';
      this.path_account = this.router.config.find(x => x.data?.id === -991)?.path || '/';
      this.path_login = this.router.config.find(x => x.data?.id === -980)?.path || '/';
      this.path_favorites = this.router.config.find(x => x.data.id === -1005).path;
      this.path_extra_1 = this.router.config.find(x => x.data?.template === 'lazy2.component')?.path || '/';
      this.path_extra_2 = this.router.config.find(x => x.data?.template === 'lazy3.component')?.path || '/';

      //default dropdown mostly for mobile
      
      this.navigationDropdown = [...this.navigation, ...this.navigationFooter];
      this.showCookies();
    });
  }

  ngAfterViewInit(): void {
    // if (this.term) {

      fromEvent(this.term.nativeElement, 'keyup').pipe(debounceTime(350), distinctUntilChanged()).subscribe((el: KeyboardEvent) => {
        const input = <HTMLInputElement>el.target;
        if (input.value.length > 2) {
          this.zone.run(() => {
            this.utilsService.setLoading();
            this.offerService.items({ title: input.value, limit: 6 }).subscribe(products => {

              this.products = products.items || [];
              this.search = true;
              this.search_term = input.value;

              this.utilsService.clearLoading();
            }, error => {
              // console.log(error);
            });
          });
        } else {
          this.products = [];
        }
        
      });

      this.loadCart();
      this.loadFooter();
    // }
  }

  searchNavigate($event: any) {
    $event.preventDefault();
    this.router.navigate(['/'+this.path_search], { queryParams: { title: this.search_term }});
    this.hideSearch();
  }

  async loadCart() {
    if (this.cartvc) {
      const { CartComponent } = await import('./shared/cart/cart.component');
      const cmp = CartComponent;
      let cartRef = this.cartvc.createComponent(cmp);
      cartRef.instance.drawerToggle.subscribe(e => {
        this.hideCartDrawer();
      })
    }
  }

  async loadFooter() {
    if (this.footervc) {
      const { FooterComponent } = await import('./shared/footer/footer.component');
      const cmp = FooterComponent;
      let footerRef = this.footervc.createComponent(cmp);
    }
  }

  toggleSearch() {
    this.search = !this.search;
  }
  hideSearch() {
    this.search = false;
    if (this.isMobile) this.searchmobile = false;
  }

  toggleMobileSearch() {
    this.searchmobile = !this.searchmobile;
  }

  toggleLanguageMenu() {
    this.currencyMenuVisible = false;
    this.languageMenuVisible = !this.languageMenuVisible;
  }
  toggleCurrencyMenu() {
    this.languageMenuVisible = false;
    this.currencyMenuVisible = !this.currencyMenuVisible;
  }

  changeCurrency(currencyCode: string) {
    this.cartService.updateCurrency(currencyCode);
    this.currencyMenuVisible = false;
  }

  toggleDropdown(e: MouseEvent, menuIndex = -1) {
    if (e.type === 'click') {
      if (menuIndex >= 0) { // && this.dropdown == 'collapsed'
        this.currentNavigationIndex = menuIndex;
        if (this.navigation[menuIndex].children?.length > 0) {

            let any_with_children = this.navigation[menuIndex].children.findIndex(p => p.children?.length > 0);
            if (any_with_children > -1) {
              this.isDropdownMultilevel = true;
            } else {
              this.isDropdownMultilevel = false;
            }
            this.navigationDropdown = this.navigation[menuIndex].children;
            if (this.navigation[menuIndex].children) {
              if(this.navigation[menuIndex].children[0].children) {
                this.navigationDropdownRecursive = this.navigation[menuIndex].children[0].children;
                if (this.navigation[menuIndex].children[0].extra_field_3) {
                  this.pageRef = this.navigation[menuIndex].children[0];
                }
              } else {
                this.navigationDropdownRecursive = [];
                this.pageRef = null;
              }
            } else {
              this.navigationDropdownRecursive = [];
              this.pageRef = null;
            }
            this.dropdown = 'expanded';

        } else {
          this.dropdown = 'collapsed';
          this.currentNavigationIndex = -1;
          this.navigationDropdownRecursive = [];
          this.pageRef = null;
        }
      }
    } else if (e.type === 'mouseleave' || e.type == null) {

      this.currentNavigationIndex = -1;
      this.dropdown = 'collapsed';
      this.navigationDropdownRecursive = [];
      this.pageRef = null;
    }
  }

  closeDropdown() {
    this.currentNavigationIndex = -1;
    this.dropdown = 'collapsed';
    this.navigationDropdownRecursive = [];
    this.pageRef = null;
  }

  fillDropDown(page: Page = null) {
    if (page.children[0].module == 'container') {
      this.navigationDropdown = [];
      page.children.forEach(p => {
        this.navigationDropdown.push(p);
        p.children.forEach(pc => {
          this.navigationDropdown.push(pc);
        });
      });
    } else {
      this.navigationDropdown = page.children;
    }
  }

  showChildren(menu: string, page: Page = null) {
    if (this.isMobile) {
      this.mobilePath[menu].push(page);
      this.fillDropDown(page);
    } else {
      if (page) {
        if (page.children) {
        this.navigationDropdownRecursive = page.children;
        }
        this.pageRef = page;
      }
    } 
  }

  hideChildren(menu: string) {
    this.mobilePath[menu].splice(-1,1);
    const pathlength = this.mobilePath[menu].length-1;
    if (pathlength > -1) {
      this.fillDropDown(this.mobilePath[menu][pathlength]);
    } else {
      // this.navigationDropdown = this.navigation;
      this.navigationDropdown = [...this.navigation, ...this.navigationFooter];
    }
  }

  showDrawer() {
    if (!this.initialized) {
      this.initialized = true;
    }
    this.mainDrawer.open();
    this.renderer.addClass(document.body, 'disabled');
  }

  hideDrawer() {
    // if (window.innerWidth < 1279) {
    this.mainDrawer.close();
    this.renderer.removeClass(document.body, 'disabled');
    // }
  }

  showCart() {
    this.cartDrawer.open();
    this.renderer.addClass(document.body, 'disabled');
    this.cdr.detectChanges();
  }

  hideCartDrawer() {
    this.cartDrawer.close();
    this.renderer.removeClass(document.body, 'disabled');
    this.cdr.detectChanges();
  }

  cartChanged = (total: number) => {

    console.log('CART CHANGED', total, this.cartInited);

    if (this.cartInited && this.cartItems < total) {
      this.showCart();
    } else {
      this.cartInited = true;
    }
    this.cartItems = total;
    this.cdr.markForCheck();
  }

  codeChanged = (code: boolean) => {
    this.withcode = code;
    this.cdr.markForCheck();
  }

  dynamicPageChanged = (page: any) => {

    let label = null;
    if ('name' in page) {
      label = page.name;
    }
    if ('title' in page) {
      label = page.title;
    }
    if ('categories' in page) {

      // const category = page.categories.find((x: any) => x.path === this.last_activated_route);
      if (this.last_activated_route) {

        while (this.last_activated_route.length) {
          const category = page.categories.find((x: any) => x.path === this.last_activated_route.join('/'));
          if (category) {
            this.breadcrumbs.unshift({ label: category.name, path: category.path });
          }
          this.last_activated_route.pop();
        }
        this.cdr.detectChanges();
      } else if (typeof page.categories !== 'undefined' && page.categories.length > 0){
        this.breadcrumbs.push({ label: page.categories[0].name, path: page.categories[0].path });
        this.cdr.detectChanges();
      }
    }
    if (label) {
      this.breadcrumbs.push({ label: label, path: 'none' });
      this.cdr.markForCheck();
    }
  }

  goTop(){
    utils.scrollTo(0, {}, 500);
  }

  showCookies() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.cookie_visible && localStorage.getItem('cookies') === null) {
        this.utilsService.openDialog({
          isSnackbar: 'success',
          content: this.cookie_content,
          actionButtons: [
            { text: 'Rozumiem', onAction: () => new Promise((resolve: any) => { 
              localStorage.setItem('cookies', 'true');
              resolve(true);
            })  },
          ],
        }, this.viewRef);
        
      }
    }
  }

}

