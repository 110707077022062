import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import { Client, ClientAddress} from '../auth/auth.model';
import { OrderDetail, Orders, Returns } from './user.model';
import { environment } from './../../environments/environment';
import { Products } from '../offer/offer.model';

@Injectable()
export class UserService {

  constructor(private httpClient: HttpClient) {}

  item(id: number): Observable<Client> {
    return this.httpClient.get<Client>(environment.api + '/protected/client/' + id);
  }

  change(client: Client): Observable<Client> {
    return this.httpClient.post<Client>(environment.api + '/protected/client/' + client.id, client);
  }

  change_password(client: Client): Observable<Client> {
    return this.httpClient.post<Client>(environment.api + '/protected/client/password', client);
  }

  orders(data: any): Observable<any> {
    return this.httpClient.post<Orders>(environment.api + '/protected/orders', data);
  }

  order_change(id: number, data: any): Observable<any> {
    return this.httpClient.post<OrderDetail[]>(environment.api + '/protected/orders/' + id, data);
  }

  users(data: any): Observable<any> {
    return this.httpClient.post<Orders>(environment.api + '/protected/client/users', data);
  }

  files(data: any): Observable<any> {
    return this.httpClient.post<Orders>(environment.api + '/protected/client/files', data);
  }

  file_confirm(data: any): Observable<any> {
    return this.httpClient.post<Orders>(environment.api + '/protected/client/files/confirm', data);
  }

  invoices(data: any): Observable<any> {
    return this.httpClient.post<Orders>(environment.api + '/protected/invoices', data);
  }

  invoice(id: number): Observable<any> {
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.httpClient.get<Blob>(environment.api + '/protected/invoice/' + id, httpOptions);
  }

  discounts(data: any): Observable<any> {
    return this.httpClient.post<Orders>(environment.api + '/protected/discounts', data);
  }

  favorites(id_client: any, uuid: string): Observable<Products> {
    return this.httpClient.get<Products>(environment.api + '/favorites/' + id_client + '/' + uuid);
  }

  evidence(data: any): Observable<string> {
    return this.httpClient.post<string>(environment.api + '/evidence', data);
  }

  fitters(): Observable<any> {
    return this.httpClient.get<any>(environment.api + '/protected/client/fitters');
  }

  total(data: any): Observable<any> {
    return this.httpClient.post<number>(environment.api + '/protected/orders', data);
  }

  returnorder(returns: Returns): Observable<Returns> {
    return this.httpClient.post<Returns>(environment.api + '/protected/return-order', returns);
  }

  deleteAddress(id: string[]) {
    const data = {'type': 'address', 'ids': id};
    return this.httpClient.post<any>(environment.api + '/protected/client/remove', data);
  }

  delete(id: string[]) {
    const data = {'type': 'client', 'ids': id};
    return this.httpClient.post<any>(environment.api + '/protected/client/remove', data);
  }

  restore(id: string[]) {
    const data = {'type': 'client_restore', 'ids': id};
    return this.httpClient.post<any>(environment.api + '/protected/client/remove', data);
  }
}

